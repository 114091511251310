<template>
  <div class="menu-items-container">
    <v-card
      v-for="menuItem in getMenuItems"
      :key="menuItem.name"
      class="menu-item"
      :class="{ 'd-none': isMenuEmpty(menuItem) }"
    >
      <v-card-title class="menu-item__header">
        <v-icon color="white" large class="mr-2">
          {{ menuItem.icon || "mdi-apps" }}
        </v-icon>
        <h3>{{ menuItem.name }}</h3>
      </v-card-title>
      <v-card-text class="pt-4">
        <p class="mb__half">{{ menuItem.description }}</p>
        <v-menu open-on-hover bottom offset-y :close-on-content-click="false">
          <template #activator="{ on, attrs }">
            <v-btn block color="primary" dark v-bind="attrs" v-on="on">
              <div class="flex align__center flex__1">
                <span class="flex__1">Available Flows</span>
                <v-icon right> mdi-chevron-down </v-icon>
              </div>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(flow, index) in getAvaiableFlows(menuItem.name)"
              :key="index"
              @click="handleFlowSelection(flow)"
            >
              <v-list-item-icon>
                <v-icon :style="getIconColor(flow.config.main_config)">
                  {{ getIcon(flow.config.main_config) }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                <p class="flow-name">{{ flow.name }}</p>
              </v-list-item-title>
              <v-list-item-icon>
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      class="ml-2"
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span class="w__full">{{ flow.description }}</span>
                </v-tooltip>
              </v-list-item-icon>
            </v-list-item>
            <v-list-group
              v-for="nestedField in menuItem.nested_fields"
              :key="nestedField"
              :class="{
                'd-none': !getAvaiableFlows(nestedField).length,
              }"
            >
              <template #activator>
                <v-list-item-icon>
                  <v-icon>mdi-menu</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ nestedField }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(flow, index) in getAvaiableFlows(nestedField)"
                :key="index"
                @click="handleFlowSelection(flow)"
              >
                <v-list-item-icon>
                  <v-icon :style="getIconColor(flow.config.main_config)">
                    {{ getIcon(flow.config.main_config) }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  <p class="flow-name">{{ flow.name }}</p>
                </v-list-item-title>
                <v-list-item-icon>
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-icon
                        class="ml-2"
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>{{ flow.description }}</span>
                  </v-tooltip>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-menu>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "MenuItemDisplay",
  computed: {
    ...mapState({
      flows: (state) => state.company.botFlows,
      menuItems: (state) => state.company.menuItems,
      currentBotUser: (state) => state.users.currentUser,
      settings: (state) => state.company.settings,
      flowsPerConnector: (state) => state.company.flowsPerConnector,
      selectedConnector: (state) => state.connectors.selectedConnector,
      isHamon: (state) => state.company.isHamon,
    }),
    getMenuItems() {
      return this.menuItems;
    },
  },
  methods: {
    ...mapMutations({
      setCurrentFlow: "company/setCurrentFlow",
    }),
    getIcon(mainConfig) {
      if (!mainConfig.icon) return "mdi-robot-excited";
      return mainConfig.icon.icon || mainConfig.icon;
    },
    getIconColor(mainConfig) {
      if (mainConfig.icon?.color) {
        const { r, g, b, a } = mainConfig.icon?.color;
        return `color: rgba(${r}, ${g}, ${b}, ${a});`;
      }
      return "color: rgba(0, 0, 0, 0.54)";
    },
    isMenuEmpty(menu) {
      const groups = this.currentBotUser?.groups.join(",");
      const rootAndNestedMenus = [menu.name];
      if (menu.nested_fields) {
        rootAndNestedMenus.push(...menu.nested_fields);
      }
      const associatedFlows = this.flows
        .filter((flow) => {
          return (
            rootAndNestedMenus.includes(flow.config.main_config.menu_name) &&
            flow.enabled &&
            !flow.config.main_config?.no_standalone_execution_ga &&
            !flow.config.main_config?.no_standalone_execution_lb
          );
        })
        .filter((flow) =>
          flow.allowed_roles.some((group) => groups?.includes(group))
        );
      return !associatedFlows.length;
    },
    handleFlowSelection(flow) {
      this.setCurrentFlow(flow);
      this.$router.push("/flow");
    },
    getAvaiableFlows(menuName) {
      const groups = this.currentBotUser?.groups.join(",");
      return this.flows
        .filter(
          (flow) =>
            menuName === flow.config.main_config.menu_name &&
            flow.enabled &&
            !flow.config.main_config?.no_standalone_execution_ga &&
            !flow.config.main_config?.no_standalone_execution_lb
        )
        .filter((flow) =>
          flow.allowed_roles.some((group) => groups?.includes(group))
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-items-container {
  display: flex;
  flex-wrap: wrap;
  gap: $page-spacing;
}
.menu-item {
  max-width: 400px;
  width: 100%;
  &__header {
    background-color: var(--title-baner-background);
    color: #fafafa;
  }
}
.flow-name {
  max-width: 240px;
  word-break: normal;
  white-space: normal;
  margin-bottom: 0;
}
</style>
