<template>
  <div>
    <div class="flex justify__end ma-2">
      <v-btn
        x-small
        color="primary"
        @click="refreshContent"
        :disabled="isLoading"
        fab
      >
        <v-icon medium> mdi-refresh </v-icon>
      </v-btn>
    </div>
    <menu-item-list v-if="!isLoading" />
    <v-skeleton-loader v-if="isLoading" type="list-item-avatar-two-line@6" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import MenuItemList from "@/components/AppMenus/MenuItemList.vue";
import MenuItemDisplay from "@/components/AppMenus/MenuItemDisplay.vue";
export default {
  name: "MenusTab",
  components: { MenuItemList, MenuItemDisplay },
  data: () => ({
    currentDomain: "",
    isLoading: false,
  }),
  created() {
    if (this.menuItems.length) return;
    this.fetchMenusAndBotflows();
  },
  computed: {
    ...mapState({
      settings: (state) => state.company.settings,
      menuItems: (state) => state.company.menuItems,
    }),
  },
  methods: {
    ...mapActions({
      fetchMenuItems: "company/fetchMenuItems",
      fetchBotFlows: "company/fetchBotFlows",
      fetchConnectorBotflows: "company/fetchBotflowsPerConnector",
    }),
    async fetchMenusAndBotflows() {
      this.isLoading = true;
      await this.fetchMenuItems(this.settings.company_id);
      await this.fetchBotFlows(this.settings.company_id);
      if (!this.isHamon) {
        await this.fetchConnectorBotflows();
      }
      this.isLoading = false;
    },
    refreshContent() {
      this.fetchMenusAndBotflows();
    },
  },
};
</script>
