<template>
  <div>
    <v-list
      class="menu-container mb-2"
      v-for="menuItem in getMenuItems"
      :key="menuItem.name"
    >
      <v-list-group
        class="menu-item"
        v-if="hasNestedFields(menuItem)"
        :prepend-icon="menuItem.icon || 'mdi-apps'"
      >
        <template #activator>
          <v-list-item class="menu-item">
            <v-list-item-content>
              <v-list-item-title>{{ menuItem.name }}</v-list-item-title>
              <v-list-item-subtitle>
                <p class="mb-0 text-wrap">{{ menuItem.description }}</p>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item
          v-for="nestedField in menuItem.nested_fields"
          :key="nestedField"
          class="menu-item"
          link
          @click="selectMenuItem(nestedField)"
          :class="{ 'd-none': !getAvaiableFlows(nestedField).length }"
        >
          <v-list-item-icon>
            <v-icon class="menu-item__icon">
              {{ menuItem.icon || "mdi-apps" }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ nestedField }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon class="menu-item__icon">mdi-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-group>
      <v-list-item
        class="menu-item"
        @click="selectMenuItem(menuItem.name)"
        v-if="!hasNestedFields(menuItem)"
        :class="{ 'd-none': !getAvaiableFlows(menuItem.name).length }"
      >
        <v-list-item-icon>
          <v-icon class="menu-item__icon">
            {{ menuItem.icon || "mdi-apps" }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ menuItem.name }}</v-list-item-title>
          <v-list-item-subtitle>
            <p class="mb-0 text-wrap">{{ menuItem.description }}</p>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon class="menu-item__icon">mdi-chevron-right</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "MenuItemList",
  props: {
    preview: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loadingDialog: false,
    associatedFlows: [],
  }),
  computed: {
    ...mapState({
      flows: (state) => state.company.botFlows,
      menuItems: (state) => state.company.menuItems,
      currentBotUser: (state) => state.users.currentUser,
      settings: (state) => state.company.settings,
      flowsPerConnector: (state) => state.company.flowsPerConnector,
      selectedConnector: (state) => state.connectors.selectedConnector,
      isHamon: (state) => state.company.isHamon,
    }),
    getMenuItems() {
      const availableMenus = [
        ...new Set(
          this.flowsPerConnector
            .filter((flow) => flow.status === "PUBLISHED")
            .map((flow) => flow.menu)
        ),
      ];
      return this.menuItems.filter((menuItem) => {
        if (this.isHamon) return true;
        if (
          availableMenus.includes(menuItem.name) ||
          menuItem.nested_fields?.filter((field) =>
            availableMenus.includes(field)
          ).length
        ) {
          return true;
        }
        return false;
      });
    },
  },
  watch: {
    flowsPerConnector(newVal, oldVal) {
      if (newVal === oldVal) return;
      this.filterAvailableFlows();
    },
  },
  mounted() {
    this.filterAvailableFlows();
  },
  methods: {
    hasNestedFields(menu) {
      return menu.nested_fields?.length > 0;
    },
    selectMenuItem(menuName) {
      this.$router.push({ name: "Menu", params: { name: menuName } });
    },
    filterAvailableFlows() {
      if (!this.flowsPerConnector.length && !this.isHamon) return;
      let groups = this.currentBotUser?.groups.join(",");
      if (this.$store.state.company.settings.id === "Hight.io-Demo") {
        groups = ["Demo group"];
      }
      if (this.isHamon) {
        this.associatedFlows = this.flows
          .filter((flow) => {
            const mainConfig = flow.config.main_config;
            return (
              flow.enabled &&
              !mainConfig?.no_standalone_execution_ga &&
              !mainConfig?.no_standalone_execution_lb
            );
          })
          .filter((flow) =>
            flow.allowed_roles.some((group) => groups?.includes(group))
          )
          .map((flow) => ({
            botflow_config: flow,
            menu: flow.config.main_config.menu_name,
          }));
        return;
      }
      this.associatedFlows = this.flowsPerConnector
        .filter((flow) => {
          const mainConfig = flow.botflow_config.config.main_config;
          return (
            flow.status === "PUBLISHED" &&
            !mainConfig?.no_standalone_execution_ga &&
            !mainConfig?.no_standalone_execution_lb
          );
        })
        .filter((flow) =>
          flow.botflow_config.allowed_roles.some((group) =>
            groups?.includes(group)
          )
        );
    },
    getAvaiableFlows(menuName) {
      return this.associatedFlows.filter((flow) => flow.menu === menuName);
    },
  },
};
</script>

<style lang="scss">
.v-list.menu-container {
  .menu-item {
    color: var(--sidebar-link-color) !important;
  }
  .v-icon {
    color: var(--sidebar-link-color) !important;
  }
}
</style>
