<template>
  <div class="page-container">
    <v-tabs v-model="currentTab" grow>
      <v-tabs-slider class="slider-color" />
      <v-tab
        class="link-color"
        v-for="tab in tabs"
        :key="tab.value"
        :href="tab.value"
      >
        <v-icon class="mr-1"> {{ tab.icon }} </v-icon> {{ tab.label }}
      </v-tab>
    </v-tabs>
    <menus-tab key="menus-tab" v-show="currentTab === 'menus-tab'" />
    <dashboard-tab
      key="dashboard-tab"
      class="mt-1"
      v-show="currentTab === 'dashboard-tab'"
    />
  </div>
</template>

<script>
import DashboardTab from "@/components/AppMenus/DashboardTab.vue";
import MenusTab from "@/components/AppMenus/MenusTab.vue";
export default {
  name: "AppMenus",
  components: { DashboardTab, MenusTab },
  data: () => ({
    currentTab: null,
  }),
  computed: {
    tabs() {
      return [
        { label: "Menus", value: "#menus-tab", icon: "mdi-apps" },
        {
          label: "Dashboard",
          value: "#dashboard-tab",
          icon: "mdi-view-dashboard",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.v-tab.link-color {
  color: var(--sidebar-link-color);
}
.v-tabs-slider.slider-color {
  background-color: var(--sidebar-link-color);
}
</style>
